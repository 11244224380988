export const IS_DEV = process.env.REACT_APP_IS_DEV && process.env.REACT_APP_IS_DEV === '1'

export const API_URL = IS_DEV
    ? 'https://dev.powerlego.com/ApiGateway/'
    : 'https://www.powerlego.com/ApiGateway/'
export const ACC_KEY = IS_DEV
    ? 'c5fb349da68ab8094c07a799e99e09ce'
    : 'c5fb349da68ab8094c07a799e99e09ce'
export const SIDEBYSIDE_API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const GOOGLE_API_KEY='AIzaSyAZM3syuwFDqeu4orgmu-9_srYHvHK0Jgk'
export const ENABLE_SOLAR_MAP = true;